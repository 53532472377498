<template>
  <div class="col-12">
    <div class="card">
      <h5>Ver Detalle de Proveedor</h5>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="">Código Proveedor</label>
          <InputText type="text" disabled v-model="proveedor.id" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Nombre Completo</label>
          <InputText type="text" disabled v-model="proveedor.nombre_completo" />
          <!-- {{ proveedor.nombre_completo ?? '' }} -->
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Tipo Proveedor</label>
          <InputText type="text" disabled v-model="tipo_proveedor" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">CI/NIT</label>
          <InputText type="text" disabled v-model="proveedor.ci_nit" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Telefono</label>
          <InputText type="text" disabled v-model="proveedor.telefono" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Contacto</label>
          <InputText type="text" disabled v-model="proveedor.contacto" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Contacto Telefono</label>
          <InputText type="text" disabled v-model="proveedor.contacto_telf" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Nombre Cheque</label>
          <InputText type="text" disabled v-model="proveedor.nombre_cheque" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Nombre LCV</label>
          <InputText type="text" disabled v-model="proveedor.nombre_lcv" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Estado</label>
          <InputText type="text" disabled v-model="texto_estado" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Fecha Creación</label>
          <InputText type="text" disabled v-model="proveedor.created_at" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Fecha Actualización</label>
          <InputText type="text" disabled v-model="proveedor.updated_at" />
        </div>
        <div class="field col-12 md:col-1">
          <Button @click="goBack">
            <i class="pi pi-arrow-circle-left"></i>
            &nbsp;ATRAS
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProveedorService from "@/service/ProveedorService";

export default {
  data() {
    return {
      proveedor: {},
      idProveedor: this.$route.params.id,
      texto_estado: '',
      tipo_proveedor: '',
    };
  },
  proveedorService: null,
  created() {
    this.proveedorService = new ProveedorService();
  },
  mounted() {
    this.proveedorService.showProveedor(this.idProveedor).then((data) => {
      this.proveedor = data;
      this.texto_estado = this.textoEstado(data.estado);
      this.tipo_proveedor = this.tipoProveedor(data.tipo_proveedor);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
    tipoProveedor(tipo_proveedor) {
      return tipo_proveedor == 1 ? "Natural" : "Jurídico";
    }
  },
};
</script>
